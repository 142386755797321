<template>
  <div class="h-100 d-flex flex-column justify-space-between">
    <v-data-table
      class="d-inline pointer overflow-auto"
      :headers="headers"
      :items="showList"
      :options.sync="options"
      hide-default-footer
      :server-items-length="total"
      @click:row="rowClick"
      @contextmenu:row.prevent="showContextMenu"
    >
      <template v-for="(header, index) in headers" #[`item.${header.value}`]="{ item }">
        <div :key="index">
          <span v-if="!header.type || header.type === 'text'">{{ item[header.value] }}</span>
          <div v-else-if="header.type === 'computed_text'">
            <span v-for="prop in header.computed" :key="prop">{{ item[prop] }} </span>
          </div>
          <v-switch
            v-else-if="header.type === 'switch'"
            v-model="item.active"
            class="ma-2 pa-0 switch"
            hide-details
            inset
            disabled
            @click.stop
          />
          <span v-else-if="header.type === 'length'"> {{ header.maxValue ? (item[header.value].length >= header.maxValue ? `${header.maxValue}+` : item[header.value].length) : item[header.value].length }}</span>
        </div>
      </template>
      <template #[`item.product`]="{ item }">
        <a v-if="item.product" href="#" @click.stop="goToPimProductPage(item['metadata.product_uuid'], item['metadata.context'])">{{ item.product }}</a>
        <span v-else>N/A</span>
      </template>
      <template #[`item.link`]="{ item }">
        <a :href="item.link" target="_blank" @click.stop>{{ item.link }}</a>
      </template>
    </v-data-table>
    <v-pagination v-model="options.page" :length="pageCount" :total-visible="10" class="pb-6" />
    <ContextMenu ref="contextMenu" class="contextMenu" @openInNewTab="rowOpenInNewTab" />
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import { getDataTableHeadersConfiguration } from '../configs/helper';
import ContextMenu from './ContextMenu.vue';
import { requests } from '@/mixins/index';

const props = defineProps({
  showList: { type: Array, default: () => [] },
  view: { type: String, default: 'groups' },
  clickAction: { type: Function, default: () => {} },
  page: { type: Number, default: 1 },
  pageCount: { type: Number, default: 0 },
  total: { type: Number, default: 0 }
});

const emit = defineEmits(['rowClick', 'rowOpenNewTab', 'update:page']);

const options = ref({});
const contextMenu = ref(null);

watch(options, newVal => {
  emit('update:page', newVal);
}, { deep: true });

const headers = ref(getDataTableHeadersConfiguration(props.view));

const rowClick = item => {
  emit('rowClick', item);
};

const goToPimProductPage = (product_uuid, context) => {
  // eslint-disable-next-line no-nested-ternary
  const project = context === 'labalaguere' ? context : context === 'vacances' ? 'speedboat' : 'alpha';

  requests.redirectToDashboard(encodeURIComponent(`${process.env.VUE_APP_ENVIROMENT === 'prod' ? `https://${project}.ucpa.com` : `https://recette.${project}.ucpa.com`}/content/product/${product_uuid}`), true);
};

const showContextMenu = (e, item) => {
  contextMenu.value.show(e, item.item);
};

const rowOpenInNewTab = item => {
  emit('rowOpenNewTab', item);
};
</script>

<style lang="scss" scoped>
  .pointer {
    cursor: pointer
  }
  .switch {
    max-width: min-content;
  }
  .h-100 {
    height: 100%;
    align-content: space-between;
  }
  .contextMenu {
    position: absolute;
    top: 0;
  }
</style>
